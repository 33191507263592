export const getDeviceSignalRateClassIcon = (ping: number = -1) : string => {
  let deviceSignalRateClass = getDeviceSignalRateClass(ping || -1);
  return deviceSignalRateClass < 0
  ? "signal_wifi_bad"
  : deviceSignalRateClass === 4
    ? "signal_wifi_4_bar"
    : deviceSignalRateClass === 0
      ? "signal_wifi_0_bar"
      : `network_wifi_${deviceSignalRateClass}_bar`;

};

export const getDeviceSignalRateClass = (ping: number = -1) : number => {
  if (ping < 0) return -1; // disconnected
  if (ping < 40) return 4;
  if (ping < 70) return 3;
  if (ping < 100) return 2;
  if (ping < 150) return 1;
  return 0;
};

export const getBase64ImageUrl = (url?: string, defaultFormat: string = "image/png"): string => {
  let returnValue = url?.toString().startsWith('data:') ? url : `data:${defaultFormat};base64,${url}`;

  // Check if the string contains ";base64" but not ";base64,"
  if (returnValue.includes(";base64") && !returnValue.includes(";base64,")) {
    // Replace ";base64" with ";base64,"
    returnValue = returnValue.replace(";base64", ";base64,");

  }

  return returnValue;
}
