// 3rd-party modules
import Tooltip from "antd/lib/tooltip";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import { message } from "antd";

// project modules
import AccountDeviceGroupCreationModal from "./AccountDeviceGroupCreationModal";
import AccountDeviceGroupModal from "./AccountDeviceGroupModal";
import AccountDeviceGroupMenuModal from "./AccountDeviceGroupMenuModal";
import Button from "../../shared/button";
import ListView from "../../shared/list/listView";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { ListViewType } from "../../shared/list/useListHook";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";

// apis
// import * as AuthApi from "../../apis/authApi"
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountDeviceGroup } from "../../../models/accountDeviceGroup";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest } from "../../../models/dataSourceRequest";
import { AccountKiboshVpnDevice } from "../../../models/accountKiboshVpnDevice";
import { AccountDevice } from "../../../models/accountDevice";
import { CommonValue } from "../../../models/commonValue";
// import AccountDeviceGroupItem from "./AccountDeviceGroupItem";
import { getBase64ImageUrl } from "../../../helpers";
import { getStringFirstLetters } from "../../../helpers/objectHelper";
import { Text } from "../../shared/text";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onMembersListChange?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceGroups({ account, refresh, accountKiboshDevice, onCancel, onSave, onMembersListChange }: Props) {
  const [accountDeviceGroups, setAccountDeviceGroups] = useState<AccountDeviceGroup[]>([]);
  const [vpnWithGroupList, setVpnWithGroupList] = useState<any[]>([]);
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup>(new AccountDeviceGroup());
  // const [currentAccountDeviceGroupVpns, setCurrentAccountDeviceGroupVpns] = useState<AccountKiboshVpnDevice[]>([]);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [showAccountDeviceGroupModal, setShowAccountDeviceGroupModal] = useState(false);
  const [showAccountDeviceGroupMenuModal, setShowAccountDeviceGroupMenuModal] = useState(false);
  const [showAccountDeviceGroupCreationModal, setShowAccountDeviceGroupCreationModal] = useState(false);

  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    setReload(true);

    return () => { abortController.abort(); }
  }, []);

  useEffect(() => {
    if (accountKiboshDevice?.accountKiboshDeviceId) {
      setReload(true);
    }
  }, [accountKiboshDevice]);

  useEffect(() => {
    if (reload) {
      getDataAsync();
      setReload(false);
    }
  }, [reload]);

  const getDataAsync = async () => {
    setLoading(true)
    const includeIcons = true;
    Promise.all([getAccountDeviceGroups(includeIcons), getAccountKiboshVpnDevices({}, abortController.signal), getAccountDevices(abortController.signal), getDeviceTypes()]).then(
      (results: any) => {
        let groups: AccountDeviceGroup[] = results[0];
        let vpnDevices: AccountKiboshVpnDevice[] = results[1];
        const devices: AccountDevice[] = results[2];
        const deviceVpnType = results[3]?.filter((x: any) => x.value === "vpn");

        if (deviceVpnType.length) {
          vpnDevices = vpnDevices.map((x: any) => {
            return {
              ...x,
              kiboshDeviceTypeId: deviceVpnType[0].commonValueId,
              kiboshDeviceType: deviceVpnType[0].valueCaption || deviceVpnType[0].value,
              kiboshDeviceReference: x.id,
              vpnType: x.vpnType,
              kiboshDeviceName: `VPN ${x.id}`,
              accountKiboshDeviceId: x.id,
              vpnId: x.vpnId || x.id
            }
          })
        }
        let vpnWithGroups: any[] = [];
        // finding group vpns
        groups.forEach(group => {
          const groupDevices = devices.filter(x => x.groupId === group.id);
          let currentVpnWithGroups = vpnDevices.filter(x => groupDevices.map(device => device.id).includes(x.associatedRouterClientId)).map((x: any) => {
            return {
              ...x,
              groupId: group.id
            }
          });
          vpnWithGroups = vpnWithGroups.concat(currentVpnWithGroups)
        });

        setVpnWithGroupList(vpnWithGroups);
        setAccountDeviceGroups(groups);

        if (!includeIcons) {
          // load images seperatly
          Promise.all(groups.map(async (item: any) => await getAccountDeviceGroupIcon(item))).then(
            (results: any) => {
              groups = groups.map((group: any, index: any) => {
                return {
                  ...group,
                  icon: results[index]
                }
              })
              setAccountDeviceGroups(groups);
            }
          )
        }
      }
    ).finally(() => {
      setLoading(false)
    });
  }

  const getAccountDeviceGroupsAsync = async () => {
    setLoading(true);
    const dataSource = await getAccountDeviceGroups(true)
    setAccountDeviceGroups(dataSource);
    setLoading(false);
    return dataSource;
  }

  const getAccountDeviceGroups = async (includeIcons: boolean) => {
    const response = await apiCall(DashApi.getDeviceGroups(accountKiboshDevice!.kiboshDeviceReference!, includeIcons, abortController.signal));

    return response.success ? AccountDeviceGroup.toArrayOfClass(response.data?.value || []).sort((a: any, b: any) => a.id - b.id) : [];
  };

  const getAccountDeviceGroupIcon = async (item: any) => {
    const response = await apiCall(DashApi.getDeviceGroupIconById(accountKiboshDevice!.kiboshDeviceReference!, item.id!, abortController.signal));

    return response.success ? response.data?.value || "" : "";
  };

  const getAccountKiboshVpnDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getAccountVpns(null, accountKiboshDevice?.kiboshDeviceReference, abortController.signal));

    return response.success ? AccountKiboshVpnDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountDevices = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const onAccountDeviceGroupSave = async (step: string = "") => {
    const dataSource = await getAccountDeviceGroupsAsync();
    switch (step) {
      case "creation":
        onDevicesClick(dataSource[dataSource.length - 1]);

        break;
      case "device-managment":
        setShowAccountDeviceGroupModal(false)
        onDetailsClick(dataSource[dataSource.length - 1]);

        break;
      case "edit":
        setReload(true)

        break;
    }

    if (onMembersListChange) onMembersListChange();
  }

  const onCreateGroupClick = () => {
    setCurrentAccountDeviceGroup(new AccountDeviceGroup());
    setShowAccountDeviceGroupCreationModal(true);
  };

  const onDetailsClick = (accountDeviceGroup: AccountDeviceGroup) => {
    setCurrentAccountDeviceGroup({ ...accountDeviceGroup });
    // setCurrentAccountDeviceGroupVpns(vpnWithGroupList.filter(x => x.groupId === accountDeviceGroup.id))
    setShowAccountDeviceGroupMenuModal(true);
  };

  const onDevicesClick = (accountDeviceGroup: AccountDeviceGroup, event?: any) => {
    if (event) {
      event.stopPropagation();
    }

    setCurrentAccountDeviceGroup({ ...accountDeviceGroup });
    setShowAccountDeviceGroupModal(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteDeviceGroup(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onDeleteClick = (data: any, e: any) => {
    e.stopPropagation();
    setCurrentAccountDeviceGroup({ ...data });
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (data: any, e: any) => {
    e.stopPropagation();
    setCurrentAccountDeviceGroup({ ...data });
    setShowAccountDeviceGroupCreationModal(true);
  }

  const BlockItem: React.FC<{ data: any }> = ({ data }) => (
    // <AccountDeviceGroupItem accountKiboshDeviceReference={accountKiboshDevice?.kiboshDeviceReference!} item={data} isMobile={false} />
    <group
      data-direction="column"
      data-contain=""
      data-border=""
      data-radius="10"
      data-background="context"
      data-cursor="pointer">
      <Button micro material icon="arrow_outward" data-position="right" onClick={() => onDetailsClick(data)} />
      {/* <group data-space="30" data-align="center" data-direction="column"> */}
      <group data-space-horizontal="30" data-align="center" data-direction="column">
        <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="100" data-radius="full" data-background="main" data-color="white">
          <picture>
            {data.icon ? <img src={getBase64ImageUrl(data.icon)} alt="" /> : <text data-text-size="xx-large" data-weight="700">{getStringFirstLetters(data.name)}</text>}
          </picture>
        </group>
      </group>
      <group data-direction="column" data-contain="" data-gap="5" data-space="15">
        <Text
          data-weight="700"
          data-ellipsis=""
          data-text-align="center">
          {data.name}
        </Text>
        <Text data-wrap="wrap" data-clamp="3" data-line="1.5">
          {data.description}
        </Text>
      </group>
      <separator data-horizontal="" data-margin="none"></separator>
      <group data-gap="5" data-space="10" data-align="center">
      {(!!data.numSetFiltersWithSchedule || !!data.isBlocked) &&
        <group data-wrap="no" data-width="auto" data-gap="15">
          {!!data.isBlocked &&
            <icon data-opacity="30">pause_circle</icon>
          }
          {!!data.numSetFiltersWithSchedule &&
            <icon data-opacity="30">schedule</icon>
          }
        </group>
      }
        {/* <Button micro material icon="arrow_outward" data-position="right" onClick={() => onDetailsClick(data)} />
        <separator data-vertical=""></separator> */}
        <Button micro material icon="edit" data-position="right" onClick={(e) => onEditClick(data, e)} />
        <Button micro material icon="delete" data-color="red" onClick={(e) => onDeleteClick(data, e)} />
      </group>
    </group>
  );

  const GridItem: React.FC<{ data: any }> = ({ data }) => (
    // <AccountDeviceGroupItem accountKiboshDeviceReference={accountKiboshDevice?.kiboshDeviceReference!} item={data} isMobile={true} />
    <group data-align="center" data-space="10" data-gap="10" data-wrap="no" data-background="highlight">
      <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="50" data-radius="full" data-background="main" data-color="white">
        <picture>
          {data.icon ? <img src={getBase64ImageUrl(data.icon)} alt="" /> : <text data-text-size="large" data-weight="700">{getStringFirstLetters(data.name)}</text>}
        </picture>
      </group>
      <group data-direction="column" data-contain="">
        <Text data-weight="700">{data.name}</Text>
        <Text data-ellipsis="">{data.description}</Text>
      </group>
    {  /*<separator data-vertical=""></separator>*/}
      <group
        data-align="center"
        data-wrap="no"
        data-position="right"
        data-width="auto"
      >
        {(!!data.numSetFiltersWithSchedule || !!data.isBlocked) &&
          <group data-wrap="no" data-width="auto" data-gap="15">
            {!!data.isBlocked &&
              <icon data-opacity="30">pause_circle</icon>
            }
            {!!data.numSetFiltersWithSchedule &&
              <icon data-opacity="30">schedule</icon>
            }
          </group>
        }
        {/* <Button mini rounded onClick={(e) => onDevicesClick(data, e)}>
          <icon>devices</icon>
        </Button>
        <separator data-vertical=""></separator> */}
        <Button mini rounded onClick={() => onDetailsClick(data)}>
          <icon data-opacity="30">chevron_right</icon>
        </Button>
      </group>
    </group>

  );

  return (
    <>
      {(loading) && <Loader />}
      <view data-vertical="">
        <view
          data-scroll=""
          data-space="20"
          data-gap="30">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Profiles</text>
            <text data-wrap="wrap" data-length="400" data-line="1.5" data-light="">Add or create new family members and groups, and edit or manage existing ones.</text>
          </group>
          <group data-width="auto" data-position="left">
            <Tooltip title="Create New" arrow={false}>
              <group data-width="auto">
                <Button
                  secondary
                  data-length="50"
                  data-height="50"
                  data-elevation="6"
                  rounded
                  icon="add"
                  disabled={!accountKiboshDevice?.kiboshDeviceReference}
                  onClick={onCreateGroupClick}
                />
              </group>
            </Tooltip>
          </group>
          <ListView
            data-adaptive="desktop"
            dataSource={accountDeviceGroups}
            view={ListViewType.Block}
            keyField="id"
            data-template="190"
            listProps={{ "data-gap": "10" }}
            itemComponent={BlockItem}
            onItemClick={onDetailsClick} />

          <group>
            <group data-contain="" data-radius="10" data-elevation="2">
              <ListView
                data-adaptive="mobile"
                view={ListViewType.List}
                dataSource={accountDeviceGroups}
                keyField="id"
                data-space="0"
                listProps={{ "data-gap": "1" }}
                itemComponent={GridItem}
                onItemClick={onDetailsClick}
                data-template="400"
              />
            </group>
          </group>


        </view>
      </view>
      {!!showAccountDeviceGroupMenuModal && (
        isMobile ?
        <AccountDeviceGroupMenuModal
          open={showAccountDeviceGroupMenuModal}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup}
          // accountDeviceGroupVpns={currentAccountDeviceGroupVpns}
          accountVpns={vpnWithGroupList}
          accountDeviceGroups={accountDeviceGroups}
          accountKiboshDevice={accountKiboshDevice!}
          onClose={() => setShowAccountDeviceGroupMenuModal(false)}
          onSave={onAccountDeviceGroupSave}
        />
        :
        <AccountDeviceGroupModal
          open={showAccountDeviceGroupMenuModal}
          showGroupEditSection={false}
          account={account!}
          accountVpns={vpnWithGroupList}
          accountDeviceGroups={accountDeviceGroups}
          accountDeviceGroup={currentAccountDeviceGroup}
          accountKiboshDevice={accountKiboshDevice!}
          onClose={() => setShowAccountDeviceGroupMenuModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {!!showAccountDeviceGroupModal && (
        <AccountDeviceGroupModal
          open={showAccountDeviceGroupModal}
          showTabs={false}
          showGroupEditSection={false}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup}
          accountKiboshDevice={accountKiboshDevice!}
          onClose={() => onAccountDeviceGroupSave("device-managment")}
          onSave={(e) => onAccountDeviceGroupSave("device-managment")}

          onMembersListChange={() => { if (onMembersListChange) onMembersListChange() }}
        />
      )}
      {!!showAccountDeviceGroupCreationModal && (
        <AccountDeviceGroupCreationModal
          accountDeviceGroup={currentAccountDeviceGroup}
          open={showAccountDeviceGroupCreationModal}
          closeOnSave={true}
          kiboshDeviceReference={accountKiboshDevice!.kiboshDeviceReference!}
          onClose={() => setShowAccountDeviceGroupCreationModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(currentAccountDeviceGroup.id ? "edit" : "creation")}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
