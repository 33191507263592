// 3rd-party modules
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// project modules
import Button from '../shared/button';
import Select from '../shared/inputs/select';
import { apiCall } from '../../helpers/apiHelper';
import { RootState } from '../../stores';
import { setLanguage } from '../../stores/userStore';
import { useAppDispatch } from '../../hooks/storeHooks';

// models
import { TopNavigationItem } from '../../models/types/navigation';
import { CommonValue } from '../../models/commonValue';

// apis
import * as CommonValueApi from '../../apis/commonValueApi';

// defines
type Props = {
  navItems: TopNavigationItem[];
  onNavItemSelect?: (navItem: TopNavigationItem) => void;
};

/*
const topNavigationItems: TopNavigationItem[] = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Product",
    link: "/product",
  },
  {
    text: "Futures",
    link: "/futures",
  },
  {
    text: "Pricing",
    link: "/pricing",
  },
  {
    text: "Reviews",
    link: "/reviews",
  },
  {
    text: "FAQ",
    link: "/faq",
  },
  {
    text: "Login",
    link: "/auth/login",
  },
];
*/

const getPostLanguages = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getCommonValues('post-language', abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function TopNavigation({ navItems, onNavItemSelect }: Props) {
  const abortController = new AbortController();
  const location = useLocation();
  const language = useSelector((state: RootState) => state.user.language) || 'en';
  const dispatch = useAppDispatch();
  const [currentSelectedItem, setCurrentSelectedItem] = useState('');
  const [currentLink, setCurrentLink] = useState('/');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [languages, setLanguages] = useState<CommonValue[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState(false);

  useEffect(() => {
    const getPostLanguagesAsync = async () => {
      setLoadingLanguages(true);
      setLanguages(await getPostLanguages(abortController.signal));
      setLoadingLanguages(false);
    }

    getPostLanguagesAsync();
  }, []);

  useEffect(() => {
    const current = `/${location.pathname.substring(1)}`;
    const selectedItem = navItems.find(x => x.link === current);

    if (selectedItem) {
      onNavItemClick(selectedItem);
    }
  }, [navItems]);

  useEffect(() => {
    setCurrentLink(`/${location.pathname.substring(1)}`);
  }, [location]);

  const isSelectedItem = (item: TopNavigationItem): Boolean => {
    return (
      currentSelectedItem &&
      currentSelectedItem === item.text
    ) ||
    (
      !currentSelectedItem &&
      (
        currentLink === item.link || !!item.children?.filter(x => x.link === currentLink).length
      )
    );
  }

  const onNavItemClick = (item: TopNavigationItem) => {
    if (onNavItemSelect && currentSelectedItem !== item.text) {
      onNavItemSelect(item)
    }

    setCurrentSelectedItem(item.text);
    setIsMobileMenuOpen(false);
  };

  const onMobileMenuItemClick = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <>
      <group data-space="20" data-index="1" data-sticky-background="main-background">
        <wrapper>
          <group
            data-gap="10"
            data-align="center"
            data-space-horizontal="10"
            data-wrap="no"
          >
            <Link data-type="group" data-width="auto" to="https://kibosh.com" onClick={() => setCurrentSelectedItem("")}>
              <logo>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="150"
                  height="20"
                  viewBox="0 0 173.91 17.531"
                >
                  <path
                    d="M5319.46,360.439h-1.7l-8.19,7.824v-7.824h-1.27v16.8h1.27v-8.28l8.43,8.28h1.7l-8.73-8.64Zm23.04,16.8v-16.8h-1.27v16.8h1.27Z"
                    transform="translate(-5308.31 -359.938)"
                  ></path>
                  <path
                    data-accent=""
                    data-fill="main-color"
                    d="M5376.82,365.076a4.357,4.357,0,0,0-1.5-3.492,6.02,6.02,0,0,0-4.05-1.284h-6.62v16.8h7.08a6.067,6.067,0,0,0,4.1-1.344,4.456,4.456,0,0,0,1.54-3.576,4.107,4.107,0,0,0-2.11-3.768,4.012,4.012,0,0,0,1.56-3.336h0Zm-4.68-.576a1.418,1.418,0,0,1,0,1.932,1.431,1.431,0,0,1-.99.348h-2.21v-2.64h2.21A1.4,1.4,0,0,1,5372.14,364.5Zm-3.2,5.928h2.67a1.464,1.464,0,0,1,1.05.384,1.489,1.489,0,0,1,0,2.052,1.438,1.438,0,0,1-1.05.4h-2.67v-2.832Zm46.32-1.728a8.366,8.366,0,0,0-2.56-6.216,8.962,8.962,0,0,0-12.51,0,8.905,8.905,0,0,0,0,12.456,8.815,8.815,0,0,0,15.07-6.24h0Zm-13.34,0a4.307,4.307,0,0,1,1.3-3.264,4.7,4.7,0,0,1,6.48,0,4.337,4.337,0,0,1,1.29,3.264,4.466,4.466,0,0,1-1.29,3.288,4.525,4.525,0,0,1-3.24,1.272,4.4,4.4,0,0,1-4.54-4.56h0Zm44.5,7.368a4.68,4.68,0,0,0,1.75-3.864,4.089,4.089,0,0,0-1.75-3.672,9.752,9.752,0,0,0-1.64-.912c-0.46-.192-1.12-0.416-1.96-0.672a9.9,9.9,0,0,1-2.3-.888,1.1,1.1,0,0,1-.58-0.936,0.946,0.946,0,0,1,.4-0.8,1.931,1.931,0,0,1,1.16-.3,2.7,2.7,0,0,1,2.61,1.872l3.6-2.112a6.425,6.425,0,0,0-2.49-2.844,7.063,7.063,0,0,0-3.75-1,6.22,6.22,0,0,0-4.17,1.44,4.739,4.739,0,0,0-1.66,3.768,4.421,4.421,0,0,0,1.27,3.312,8.6,8.6,0,0,0,3.72,1.944c1.15,0.336,1.86.552,2.11,0.648a1.466,1.466,0,0,1,1.13,1.2c0,0.752-.66,1.128-1.97,1.128a3.341,3.341,0,0,1-3.31-2.232l-3.67,2.136a5.95,5.95,0,0,0,2.48,3.072,8.128,8.128,0,0,0,4.38,1.1A7.258,7.258,0,0,0,5446.42,376.068Zm26.8-9.552V360.3h-4.29v16.8h4.29v-6.456h4.73V377.1h4.27V360.3h-4.27v6.216h-4.73Z"
                    transform="translate(-5308.31 -359.938)"
                  ></path>
                </svg>
              </logo>
            </Link>
            {/* <separator data-vertical="" data-height="20"></separator>
            <group data-width="auto" data-position="right">
              <nav launcher="">
                <ul data-gap="5">
                  {navItems.map((navItem) => (
                    <li
                      className={isSelectedItem(navItem) ? "selected" : undefined}
                      key={navItem.text}>
                      <Link
                        to={navItem.link || ""}
                        data-wrap="no"
                        data-align="center"
                        data-gap="5"
                        data-height="30"
                        onClick={() => onNavItemClick(navItem)}>
                        <text data-adaptive="desktop" data-space-horizontal="20">
                          {navItem.text}
                        </text>
                      </Link>
                    </li>
                  ))}
                </ul>
              </nav>
            </group>
            <separator data-vertical="" data-height="20"></separator>
            <group data-width="auto" data-align="center" data-gap="5" data-wrap="no">
              <group data-interactive="" data-width="auto" data-radius="5" data-weight="600">
                <Select
                  data-type="interact"
                  borderLess
                  dataLength="60"
                  defaultValue={language}
                  onChange={(value: any) => dispatch(setLanguage(value))}
                  options={
                    languages?.map((item) => {
                      return {
                        text: item.valueCaption!,
                        value: item.value!,
                      };
                    }) || []
                  }
                />
              </group>
              <Button
                mini
                large
                rounded
                data-adaptive="mobile"
                icon="menu"
                data-position="right"
                onClick={onMobileMenuItemClick}
              ></Button>
            </group> */}
          </group>
        </wrapper>
      </group>
      {/* <group data-name={isMobileMenuOpen ? "mobile-navigation nav_open" : "mobile-navigation"}>
        <group data-elevation="5" data-align="center" data-justify="center" data-direction="column" data-space="10" data-background="main-background" data-length="250" data-shrink="0" >
          <div className="list_menu">
            <ul data-gap="5">
              {navItems.map((navItem) => (
                <li
                  className={isSelectedItem(navItem) ? "selected" : undefined}
                  key={navItem.text}>
                  <Link
                    to={navItem.link || ""}
                    data-wrap="no"
                    data-align="center"
                    data-gap="5"
                    data-height="30"
                    onClick={() => onNavItemClick(navItem)}>
                    <text data-space-horizontal="15">
                      {navItem.text}
                    </text>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </group>
      </group> */}
    </>
  );
}
