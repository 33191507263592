import React from 'react';

interface Activity {
  start: string; // Example: '10:15'
  end: string;   // Example: '12:30'
}

interface TimelineProps {
  activities: Activity[];
}

const Timeline: React.FC<TimelineProps> = ({ activities }) => {
  const totalIntervals = 24 * 4; // 24 hours x 4 intervals (15 minutes each)
  const totalMinutes = 24 * 60;

  // Convert time to minutes (e.g., "10:15" => 615)
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  return (
    <div className="timeline-container">
      {/* Hour Markers */}
      <div className="timeline-markers">
        {Array.from({ length: 25 }, (_, i) => (
          <span key={i} className="hour-marker">
            {i}
          </span>
        ))}
      </div>

      {/* Timeline with 15-minute intervals */}
      <div className="timeline-grid">
        {Array.from({ length: totalIntervals }, (_, i) => (
          <div key={i} className="interval" />
        ))}

        {/* Activity Blocks */}
        {activities.map((activity, index) => {
          const startMinutes = timeToMinutes(activity.start);
          const endMinutes = timeToMinutes(activity.end);
          const widthPercent = ((endMinutes - startMinutes) / totalMinutes) * 100;
          const leftPercent = (startMinutes / totalMinutes) * 100;

          return (
            <div
              key={index}
              className="activity-block"
              style={{
                left: `${leftPercent}%`,
                width: `${widthPercent}%`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Timeline;
