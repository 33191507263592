// 3rd-party modules
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// project modules
import AccountKiboshDeviceModal from "../../components/accounts/kibosh-device/AccountKiboshDeviceModal";
import Loader from "../../components/shared/loader";
import { apiCall } from "../../helpers/apiHelper";
import { RootState } from "../../stores";
import { CONSTANTS } from "../../helpers/defines";

// apis
import * as AuthApi from "../../apis/authApi"
import * as DashApi from "../../apis/dashApi";

// modals
import { AccountKiboshDevice } from "../../models/accountKiboshDevice";
import { AccountKiboshDeviceStatus } from "../../models/accountKiboshDeviceStatus";
import { AccountDevice, AccountDeviceBandwidthLog } from "../../models/accountDevice";
import { isMobile } from "react-device-detect";
import { Account } from "../../models/account";

export default function HomePage() {
  const kiboshDevice = useSelector((state: RootState) => state.user?.kiboshDevice) || new AccountKiboshDevice();

  const [currentAccount, setCurrentAccount] = useState<Account>(new Account());
  const [currentKiboshDeviceStatus, setCurrentKiboshDeviceStatus] = useState<AccountKiboshDeviceStatus>(new AccountKiboshDeviceStatus());
  const [connectedDevicesCount, setConnectedDevicesCount] = useState<number>(0);
  const [vpnsCount, setVpnsCount] = useState<number>(0);
  const [malwaresCount, setMalwaresCount] = useState<number>(0);
  const [downloadSpeed, setDownloadSpeed] = useState<number>(0);
  const [uploadSpeed, setUploadSpeed] = useState<number>(0);
  const [downloadSpeedUnit, setDownloadSpeedUnit] = useState<string>("MB");
  const [uploadSpeedUnit, setUploadSpeedUnit] = useState<string>("MB");
  const [loading, setLoading] = useState<boolean>(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [malwareLastUpdate, setMalwareLastUpdate] = useState<string>("");
  const [showAccountKiboshDeviceModal, setShowAccountKiboshDeviceModal] = useState(false);
  const [malwareDevicesOnly, setMalwareDevicesOnly] = useState(false);
  let abortController = new AbortController();

  const listItems: any[] = [
    {
      id: "details",
      name: "Data",
      icon: "info",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "devices",
      name: "Devices",
      icon: "devices",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "vpns",
      name: "VPNs",
      icon: "vpn_lock",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "bandwidth-logs",
      name: "Bandwidth",
      icon: "settings_ethernet",
      isActive: true,
      mode: "all",
    },
  ];
  const settingsListItems: any[] = [
    {
      id: "settings-WiFi-settings",
      name: "WiFi Settings",
      icon: "network_manage",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-port-forwarding",
      name: "Port Forwarding",
      icon: "arrows_outward",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-firmware-upgrade",
      name: "Firmware Upgrade",
      icon: "upgrade",
      isActive: true,
      mode: "no-vpn",
    },
    {
      id: "settings-filtration",
      name: "Raw Internet",
      icon: "filter_alt",
      isActive: true,
      mode: "no-vpn",
    },
  ];

  useEffect(() => {
    let abortController = new AbortController();

    return () => { abortController.abort(); }
  }, []);

  useEffect(() => {
    getDataAsync();
  }, [kiboshDevice]);

  const getDataAsync = async () => {
    if (!kiboshDevice.kiboshDeviceReference) {
      return;
    }
    setLoading(true);
    Promise.all([
      getAccountKiboshDeviceStatus(abortController.signal),
      getAccountDevices(abortController.signal),
      getDeviceLatestBandwidths(abortController.signal),
      getAccount(abortController.signal),
      getMalwareLastUpdate(abortController.signal)
    ]).then(
      (results: any) => {
        const kiboshDeviceStatus = results[0];
        const devices = results[1];
        const bandwidth = results[2];

        if (results[3]) {
          setCurrentAccount(results[3]);
        }

        const malwareLastUpdateData = results[4];

        if (devices && devices.length > 0) {
          setConnectedDevicesCount(devices.filter((x: AccountDevice) => x.pingMs && x.pingMs > 0).length);
          setVpnsCount(devices.filter((x: AccountDevice) => x.isVpn).length);
          setMalwaresCount(devices.map((x: AccountDevice) => x.detectedMalware ? x.detectedMalware.filter(malware => moment().add(-24, "hours") < moment(malware.lastQueried)).length : 0).reduce((a: any, b: any) => a + b));
        }

        setCurrentKiboshDeviceStatus(kiboshDeviceStatus);

        const downloadSpeedObj = getNormalizedSpeedWithUnit(bandwidth.length ? bandwidth[0].intervalDownloadBandwidth : 0);
        const uploadSpeedObj = getNormalizedSpeedWithUnit(bandwidth.length ? bandwidth[0].intervalUploadBandwidth : 0);

        setDownloadSpeed(downloadSpeedObj.speed);
        setDownloadSpeedUnit(downloadSpeedObj.unit);
        setUploadSpeed(uploadSpeedObj.speed);
        setUploadSpeedUnit(uploadSpeedObj.unit);

        setMalwareLastUpdate(malwareLastUpdateData ? moment(malwareLastUpdateData).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)  : "")
      }
    ).finally(() => {
      setLoading(false);
    });
  }

  const getAccount = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(AuthApi.getAccount(abortSignal));
    return response.success ? Account.toClass(response.data?.value) : null;
  };

  const getAccountDevices = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getClientDevices(kiboshDevice?.kiboshDeviceReference!, abortSignal));
    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDeviceStatus = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getKiboshDeviceStatus(kiboshDevice?.kiboshDeviceReference!, abortSignal));
    return response.success ? AccountKiboshDeviceStatus.toClass(response.data?.value) : null;
  };

  const getDeviceLatestBandwidths = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getDeviceLatestBandwidths(kiboshDevice?.kiboshDeviceReference!, true, abortSignal));

    return response.success ? AccountDeviceBandwidthLog.toArrayOfClass(response.data?.value || []) : [];
  };

  const getMalwareLastUpdate = async (abortSignal?: AbortSignal) => {
    const response = await apiCall(DashApi.getMalwareLastUpdate(kiboshDevice?.kiboshDeviceReference!, abortSignal));
    return response.success ? response.data?.value : null;
  };

  const getInternetLatencyClass = (): string => {
    const ping = currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs;
    if (!ping) {
      return "No Connection"
    }
    else if (ping < 41) {
      return "Excellent"
    }
    else if (ping < 81) {
      return "Good"
    }
    else if (ping < 151) {
      return "OK"
    } else {
      return "Poor"
    }
  }

  const getInternetLatencyClassColor = (): string => {
    const internetLatencyClass = getInternetLatencyClass();
    switch (internetLatencyClass) {
      case "Excellent":
        return "blue";
      case "Good":
        return "green";
      case "OK":
        return "orange";
      default:
        return "red";
    }
  }

  const getNormalizedSpeedWithUnit = (data: any): any => {
    let unit = "B";
    let speed = data;

    let unitClass = 1;
    while (speed / 1024 >= 1) {
      speed /= 1024;
      unitClass += 1;
    }

    switch (unitClass) {
      case 1:
        unit = "B";
        break;
      case 2:
        unit = "KB";
        break;
      case 3:
        unit = "MB";
        break;
      case 4:
        unit = "GB";
        break;
      case 5:
        unit = "TB";
        break;
      case 6:
        unit = "PB";
        break;
    }

    return { unit, speed }
  }

  const onMalwareClick = () => {
    if (malwaresCount) {
      setMalwareDevicesOnly(true);
      setPreSelectedTab("devices");
      setShowAccountKiboshDeviceModal(true);
    }
  }

  const onSelectedTab = (selectedTab: any) => {
    if (selectedTab) {
      setMalwareDevicesOnly(false);
      setPreSelectedTab(selectedTab);
      setShowAccountKiboshDeviceModal(true);
    }
  };

  const onFirmwareClick = () => {
    if (currentKiboshDeviceStatus?.latestFirmwareVersion === currentKiboshDeviceStatus?.kiboshDevice?.currentFirmwareVersion) {
      setMalwareDevicesOnly(false);
      setPreSelectedTab("settings-firmware-upgrade");
      setShowAccountKiboshDeviceModal(true);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <view data-vertical="">
        <view
          data-scroll=""
          data-space="20">
          <group data-gap="10" data-direction="column">
            <text data-weight="700" data-text-size="xx-large" data-wrap="wrap" data-color="main-dark">Home</text>
          </group>
          <section data-space="1" data-height={ isMobile ? 'autofit' : undefined} data-margin-bottom={ isMobile ? "80" : undefined}>
            <group data-gap="5" data-height={ isMobile ? 'fit' : undefined}>
              <group>
                <space data-height="5"></space>
                <group data-gap="10" data-direction="column">
                  {
                    currentKiboshDeviceStatus?.kiboshDevice?.connectionStatus === "connected" && currentKiboshDeviceStatus?.kiboshDevice?.remoteDnsStatus === "connected" ?
                      <text data-weight="600" data-wrap="wrap">Your Kibosh is <text data-color="main-dark">online</text> and Internet <text data-color="main-dark">protected</text></text> :
                      currentKiboshDeviceStatus?.kiboshDevice?.connectionStatus === "connected" ?
                        <text data-weight="600" data-wrap="wrap">Your Kibosh is <text data-color="main-dark">online</text></text> :
                        <text data-weight="600" data-wrap="wrap">Your Kibosh is offline</text>
                  }
                  <text data-weight="600" data-wrap="wrap">
                    Last Checkin:
                    <text data-weight="700" data-wrap="wrap" data-color="main-dark">
                      {currentKiboshDeviceStatus?.kiboshDevice?.lastDeviceCheckin ? moment(currentKiboshDeviceStatus?.kiboshDevice?.lastDeviceCheckin).format(CONSTANTS.DEFAULT_DATETIME_FORMAT) : "-"}
                    </text>
                  </text>
                  <text data-wrap="wrap" data-weight="600">
                    WAN IP:
                    <text data-weight="700" data-wrap="wrap" data-color="main-dark">
                      {currentKiboshDeviceStatus?.kiboshDevice?.currentExternalIp || "-"}
                    </text>
                  </text>
                  <text data-wrap="wrap" data-weight="600" data-cursor={currentKiboshDeviceStatus?.latestFirmwareVersion === currentKiboshDeviceStatus?.kiboshDevice?.currentFirmwareVersion ? undefined : "pointer"} onClick={onFirmwareClick}>
                    Firmware:
                    <text data-weight="700" data-wrap="wrap" data-color={currentKiboshDeviceStatus?.latestFirmwareVersion === currentKiboshDeviceStatus?.kiboshDevice?.currentFirmwareVersion ? "main-dark" : "red"}>
                      {currentKiboshDeviceStatus?.kiboshDevice?.currentFirmwareVersion || "-"}
                    </text>
                  </text>
                </group>
                {/* <space data-height="40"></space> */}
              </group>
              <group data-gap="10" data-space-vertical="15" data-type="grid" data-grid-template="160">
                <group data-space-horizontal="20" data-space-vertical="5" data-direction="column" data-align="center" data-weight="600">
                  <group data-length="70" data-height="70" data-radius="50">
                    <icon data-text-size="xx-large" data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="500" data-color="main" data-elevation="5" data-background="main-color">language</icon>
                    <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background={currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs && currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs > 0 ? "main" : "red"} data-border="" data-top="0" data-right="0"></status>
                  </group>
                  <space data-height="10"></space>
                  <text data-weight="600">Internet</text>
                  <text>{currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs && currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs > 0 ? `${currentKiboshDeviceStatus?.kiboshDevice?.wanPingMs.toFixed(2)} ms` : "Not Connected"}</text>
                  <text data-weight="600" data-color={getInternetLatencyClassColor()}>{getInternetLatencyClass()}</text>
                </group>
                <group data-space-horizontal="20" data-space-vertical="5" data-direction="column" data-align="center" data-weight="600" data-cursor="pointer" onClick={() => onSelectedTab("devices")}>
                  <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                    <text data-text-size="large">{connectedDevicesCount}</text>
                    <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="main" data-border="" data-top="0" data-right="0"></status>
                  </group>
                  <space data-height="10"></space>
                  <text data-max-length="100" data-wrap="wrap" data-text-align="center">Connected Devices</text>
                </group>
                <group data-space-horizontal="20" data-space-vertical="5" data-direction="column" data-align="center" data-weight="600" data-cursor="pointer" onClick={() => onSelectedTab("vpns")}>
                  <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                    <text data-text-size="large">{vpnsCount}</text>
                    <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background="main" data-border="" data-top="0" data-right="0"></status>
                  </group>
                  <space data-height="10"></space>
                  <text>VPNs</text>
                </group>
                <group data-space-horizontal="20" data-space-vertical="5" data-direction="column" data-align="center" data-weight="600" data-cursor={ malwaresCount ? "pointer" : ""} onClick={onMalwareClick}>
                  <group data-length="70" data-height="70" data-radius="50" data-justify="center" data-align="center" data-weight="600" data-elevation="5" data-background="main-color">
                    <text data-text-size="large">{malwaresCount}</text>
                    <status data-position="absolute" data-length="15" data-height="15" data-radius="50" data-justify="center" data-background={!!malwaresCount ? "red" : "main"} data-border="" data-top="0" data-right="0"></status>
                  </group>
                  <space data-height="10"></space>
                  <text data-max-length="100" data-wrap="wrap" data-text-align="center" >Malware</text>
                  {!!malwareLastUpdate &&
                  <>
                    {/* <text data-max-length="160" data-wrap="wrap" data-text-align="center" data-color="main">Definition:</text> */}
                    <text data-max-length="160" data-wrap="wrap" data-text-align="center" data-color="main">{malwareLastUpdate}</text>
                  </>
                  }
                </group>
              </group>
              <group>
                <separator data-horizontal=""></separator>
                <group data-cursor="pointer" onClick={() => onSelectedTab("details")}>
                  <group data-type="column" data-column-size="160" data-wrap="no">
                    <group data-space="20" data-align="center">
                      <group data-direction="column" data-length='160'>
                        <text data-text-size="x-large" data-text-align="center" data-light="">{(downloadSpeed).toFixed(2)}</text>
                        <group>
                          <icon data-icon-size="48" data-color="main-dark">arrow_downward</icon>
                          <group data-direction="column" data-fit="1" data-justify="center">
                            <text data-weight="600" data-color="main-dark">Download</text>
                            <text data-light="">{downloadSpeedUnit}</text>
                          </group>
                        </group>
                      </group>
                    </group>
                    <group data-space="20" data-align="center">
                      <group data-direction="column" data-length='160'>
                        <text data-text-size="x-large" data-text-align="center" data-light="">{(uploadSpeed).toFixed(2)}</text>
                        <group>
                          <icon data-icon-size="48" data-color="main-dark">arrow_upward</icon>
                          <group data-direction="column" data-fit="1" data-justify="center">
                            <text data-weight="600" data-color="main-dark">Upload</text>
                            <text data-light="">{uploadSpeedUnit}</text>
                          </group>
                        </group>
                      </group>
                    </group>
                  </group>
                  <group data-justify={isMobile ? "center" : ""}>
                    <text data-weight="600" data-text-align="center" data-color="blue">Total Data Usage last 15 Min</text>
                  </group>
                </group>
              </group>
            </group>
          </section>
        </view>
      </view>
      {!!showAccountKiboshDeviceModal && (
        <AccountKiboshDeviceModal
          modalTitle={
              `${
                !kiboshDevice.accountKiboshDeviceId
                  ? "New Kibosh Device"
                  : `Kibosh Device Name: ${kiboshDevice.kiboshDeviceName}`
              }` +
            " - " +
            [...listItems, ...settingsListItems].find(
              (x) => x.id === preSelectedTab
            ).name
          }
          open={showAccountKiboshDeviceModal}
          showTabs={false}
          closeOnSave={true}
          account={currentAccount!}
          accountKiboshDevice={kiboshDevice}
          malwareDevicesOnly={malwareDevicesOnly}
          preSelectedTab={preSelectedTab}
          size="fullscreen"
          onClose={() => setShowAccountKiboshDeviceModal(false)}
        />
      )}
    </>
  );
}
