// 3rd-party modules
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// project modules
import Ripple from '../shared/additionalComponents/Ripple';

// models
import { SubNavigationItem, TopNavigationItem } from '../../models/types/navigation';

// icons
import AccountSvg from '../../assets/icons/account.svg';
import DashSvg from '../../assets/icons/dash.svg';
import HouseSvg from '../../assets/icons/house.svg';
import ProfileSvg from '../../assets/icons/profile.svg';
import SupportSvg from '../../assets/icons/support.svg';

// defines
const topNavigationItems: TopNavigationItem[] = [
  {
    id: 'home',
    text: 'Home',
    icon: 'home',
    link: '/portal',
    customIcon: HouseSvg
  },
  {
    id: 'profiles',
    text: 'Profiles',
    icon: 'supervisor_account',
    link: '/portal/profiles',
    customIcon: ProfileSvg
  },
  {
    id: 'dash',
    text: 'Dash',
    icon: 'dashboard',
    link: '/portal/dash',
    customIcon: DashSvg
  },
  {
    id: 'account-details',
    text: 'Account',
    icon: 'verified_user',
    link: '/portal/account-details', // '/portal/account-details',
    customIcon: AccountSvg
  },
  {
    id: 'help-desk',
    text: 'Help Desk',
    icon: 'support_agent',
    link: '/portal/support-tickets',
    customIcon: SupportSvg
  }
  // {
  //   id: 'menu',
  //   text: 'Menu',
  //   icon: 'Menu',
  //  // link: '/portal/support-tickets',
  // },
];

type Props = {
  onNavItemSelect?: (navItems: SubNavigationItem[]) => void;
};

export default function PortalTopNavigation({ onNavItemSelect }: Props) {
  const location = useLocation();
  const [currentSelectedItem, setCurrentSelectedItem] = useState('');
  const [currentLink, setCurrentLink] = useState('/');

  useEffect(() => {
    const current = `/${location.pathname.substring(1)}`;
    const selectedItems = topNavigationItems.filter(x => x.link === current || x.children?.filter(y => y.link === current).length);

    if (selectedItems.length) {
      onNavItemClick(selectedItems[0]);
    }
  }, []);

  useEffect(() => {
    const url = `/${location.pathname.substring(1)}`;
    setCurrentLink(url);
    const selectedItems = topNavigationItems.filter(x => x.link === url || x.children?.filter(y => y.link === url).length);
    if (selectedItems.length) {
      setCurrentSelectedItem(selectedItems[0].text);
    }
  }, [location]);

  const isSelectedItem = (item: TopNavigationItem): Boolean => {
    return (
      currentSelectedItem &&
      currentSelectedItem === item.text
    ) ||
      (
        !currentSelectedItem &&
        (
          currentLink === item.link || !!item.children?.filter(x => x.link === currentLink).length
        )
      );
  }

  const onNavItemClick = (item: TopNavigationItem) => {
    setCurrentSelectedItem(item.text);

    if (onNavItemSelect) {
      onNavItemSelect(item.children || [])
    }
  };

  return (
    <>
      {/* <group
        data-width="auto"
        data-align="center"
        data-gap="15"
        data-contain=""
      >
        <nav launcher="" >
          <ul>
            {topNavigationItems.map(item =>
            <li className={isSelectedItem(item) ? 'selected' : undefined} key={item.text}>
              <Link to={item.link || ""} data-gap="5" onClick={() => onNavItemClick(item)}>
                <text data-space="15" data-ellipsis="">{item.text}</text>
              </Link>
            </li>)
            }
          </ul>
        </nav>
      </group> */}

          <group
          data-background="highlight"
          data-space="10"
          >
            <group  data-weight="600" data-grid-template="120" data-gap="5" data-wrap="no" data-name="navigation">
              {topNavigationItems.map(item =>
                <Ripple key={item.id}>
                  <Link data-name="navigation_item" to={item.link || ""} data-gap="5" data-max-length="200" onClick={() => onNavItemClick(item)} data-type="group" data-contain="" data-radius="10" data-background={isSelectedItem(item) && "main"} data-ink-color={isSelectedItem(item) && "main-dark"}>
                    <group  data-contain="" data-interactive="" data-index="2"  data-cursor="pointer" data-color={isSelectedItem(item) && "white"}>
                      <group data-direction="column" data-align="center" data-space="10" data-gap="5" >
                        {!!item.customIcon ?
                          <img src={item.customIcon} className={isSelectedItem(item) ? "selected-item-icon" : ""} alt={item.text}/>
                        :
                        !!item.icon && <icon>{item.icon}</icon>
                        }
                        <text data-ellipsis="">{item.text}</text>
                      </group>
                    </group>
                  </Link>
                </Ripple>
                )
              }
            </group>
            <group data-height="15" data-adaptive="ios"></group>
          </group>




    </>
  );
}
