// 3rd-party modules
import moment from "moment-timezone";
import { Tooltip, message } from "antd";
import { useDebouncedCallback } from "use-debounce";
import { useEffect, useRef, useState } from "react";

// project modules
import AccountDeviceGroupCreationModal from "./AccountDeviceGroupCreationModal";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import { getBase64ImageUrl } from "../../../helpers";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import AccountDeviceGroupModal from './AccountDeviceGroupModal';
import ListView from "../../shared/list/listView";
import { ListViewType } from "../../shared/list/useListHook";
import Ripple from "../../shared/additionalComponents/Ripple";
import Marquee from "../../shared/additionalComponents/Marquee";
import { Text } from "../../shared/text";
import Button from "../../shared/button";
import { apiCall } from "../../../helpers/apiHelper";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import { getStringFirstLetters } from "../../../helpers/objectHelper";
import { AccountKiboshVpnDevice } from "../../../models/accountKiboshVpnDevice";
import AccountKiboshDeviceMenuModal from "../kibosh-device/AccountKiboshDeviceMenuModal";
import Scroll from "../../shared/scroll";
import { SvgDoodle } from "../../svg";
// import AccountApplicationsListModal from "../applications/AccountApplicationsListModal";

type Props = {
  account: Account;
  accountDeviceGroup: AccountDeviceGroup;
  accountKiboshDevice: AccountKiboshDevice;
  accountDeviceGroups?: AccountDeviceGroup[];
  accountVpns?: any[];
  closeOnSave?: boolean;
  modalTitle?: string;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

const INITIAL_REPEAT_LENGHT = 100;

export default function AccountDeviceGroupMenuModal({ account, accountDeviceGroup, accountKiboshDevice, accountVpns = [], accountDeviceGroups = [], closeOnSave = false, modalTitle = "", open, onClose, onSave }: Props) {
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup>(accountDeviceGroup);
  const [currentAccountDeviceGroups, setCurrentAccountDeviceGroups] = useState<AccountDeviceGroup[]>(accountDeviceGroups.length > 1 ? [].concat(...Array(Math.floor(INITIAL_REPEAT_LENGHT / accountDeviceGroups.length)).fill(accountDeviceGroups)) : accountDeviceGroups);
  const [currentAccountKiboshVpnDevice, setCurrentAccountKiboshVpnDevice] = useState<AccountKiboshVpnDevice>(new AccountKiboshVpnDevice());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [showAccountDeviceGroupModal, setShowAccountDeviceGroupModal] = useState(false);
  const [showAccountDeviceGroupCreationModal, setShowAccountDeviceGroupCreationModal] = useState(false);
  const [showAccountKiboshVpnDeviceMenuModal, setShowAccountKiboshVpnDeviceMenuModal] = useState(false);
  const [timer, setTimer] = useState("00:00:00");
  const [visibleListItems, setVisibleListItems] = useState<any[]>([]);
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false);

  const listItems: any[] = [
    {
      id: 'details',
      name: 'Devices',
      icon: "devices",
      isActive: true
    },
    {
      id: 'vpns',
      name: 'VPN',
      icon: "vpn_lock",
      isActive: true
    },
    {
      id: 'applications',
      name: 'App’s',
      icon: "apps",
      isActive: true
    },
    {
      id: 'blocked-sites',
      name: 'Website’s',
      icon: "app_blocking",
      isActive: true
    },
    {
      id: 'internet-schedules',
      name: 'Scheduler',
      icon: "event_available",
      isActive: true
    },
    {
      id: 'internet-pause',
      name: 'Pause',
      icon: "signal_cellular_pause",
      isActive: true
    },
    {
      id: 'history',
      name: 'History',
      icon: "history",
      isActive: true
    },
    {
      id: 'bandwidth',
      name: 'Bandwidth',
      icon: "settings_ethernet",
      isActive: true
    }
  ]

  const intervalRef = useRef<null | ReturnType<typeof setInterval>>(null);
  const abortController = new AbortController();

  useEffect(() => {
    if (accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)?.length) {
      setVisibleListItems(listItems)
    }
    else {
      setVisibleListItems(listItems.filter(x => x.id !== "vpns"))
    }
    if (currentAccountDeviceGroup.isBlocked) {
      startTimer();
      intervalRef.current = setInterval(() => startTimer(), 1000);
      // Cleanup on unmount or when dependencies change
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }
  }, [currentAccountDeviceGroup])

  useEffect(() => {
    if (open) {
      const items = document.getElementsByClassName("item selected");
      if (items.length) {
        const middleItemArrayIndex = accountDeviceGroups.length > 1 ? Math.floor(Math.floor(INITIAL_REPEAT_LENGHT / accountDeviceGroups.length) / 2) : 0;
        items[middleItemArrayIndex].scrollIntoView();
      }
    }
  }, [open])

  useEffect(() => {
    setCurrentAccountDeviceGroups(accountDeviceGroups.length > 1 ? [].concat(...Array(Math.floor(INITIAL_REPEAT_LENGHT / accountDeviceGroups.length)).fill(accountDeviceGroups)) : accountDeviceGroups)
  }, [accountDeviceGroups])

  useEffect(() => {
    if (showAccountDeviceGroupModal) {
      // Clear the interval if the modal is shown
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    } else if (currentAccountDeviceGroup.isBlocked) {
      // Start the timer and set the interval
      if (!intervalRef.current) {
        startTimer();
        intervalRef.current = setInterval(() => startTimer(), 1000);
      }
    }

    // Cleanup on unmount or when dependencies change
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [showAccountDeviceGroupModal])

  const scrollIntoDebounced = useDebouncedCallback(
    // function
    (item: HTMLElement) => {
      item.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    },
    // delay in ms
    200
  );

  const enableButtonsDebounced = useDebouncedCallback(
    // function
    () => {
      setDisabledButtons(false)
    },
    // delay in ms
    250
  );

  const onAccountDeviceGroupSave = (e?: AccountDeviceGroup) => {
    if (e) {
      setLoading(true)
      setCurrentAccountDeviceGroup(e)
      setLoading(false)
      if (onSave) onSave();
    }
  }

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onDeleteClick = () => {
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onSelectedTab = (selectedTab: any) => {
    if (selectedTab) {
      if (selectedTab === "vpns") {
        setCurrentAccountKiboshVpnDevice({ ...accountVpns?.filter(x => x.groupId === currentAccountDeviceGroup.id)[0], kiboshDeviceReference: accountKiboshDevice?.kiboshDeviceReference });
        setShowAccountKiboshVpnDeviceMenuModal(true)
      } else {
        setPreSelectedTab(selectedTab);
        setShowAccountDeviceGroupModal(true);
      }
    }
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteDeviceGroup(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      if (onSave) onSave();
      onCancel();
    }
  };

  const onEdit = () => {
    setShowAccountDeviceGroupCreationModal(true);
  }

  const getTimeRemaining = () => {
    const now = moment(); //todays date
    const end = moment(currentAccountDeviceGroup.blockedUntil); // another date
    const duration = moment.duration(end.diff(now));
    const total = duration.asSeconds();
    const seconds = Math.floor(
      total % 60
    );
    const minutes = Math.floor(
      (total / 60) % 60
    );
    const hours = Math.floor(
      (total / 60 / 60) % 24
    );
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = () => {
    let { total, hours, minutes, seconds } = getTimeRemaining();
    if (total >= 0) {
      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9
          ? minutes
          : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };
  const itemRefs = useRef<(HTMLElement | null)[]>([]);

  const getCenteredItemIndex = () : number => {
    const container = itemRefs.current[0]?.parentElement;
    let closestIndex = 0;
    if (container) {
      const containerRect = container.getBoundingClientRect();
      const containerMiddle = containerRect.left + containerRect.width / 2;

      let closestDistance = Infinity;

      itemRefs.current.forEach((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const itemMiddle = rect.left + rect.width / 2;
          const distance = Math.abs(containerMiddle - itemMiddle);

          if (distance < closestDistance) {
            closestIndex = index;
            closestDistance = distance;
          }
        }
      });
    }
    return closestIndex;
  };

  const onPrevClick = () => {
    setDisabledButtons(true)
    let prevItemIndex = accountDeviceGroups.findIndex(x => x.id === currentAccountDeviceGroup.id) - 1;
    if (prevItemIndex < 0) {
      prevItemIndex = accountDeviceGroups.length - 1;
    }
    const currentItemIndex = getCenteredItemIndex();
    let prevUIItemIndex = currentItemIndex - 1;

    // to scroll to middle one if it's the last section of the array
    if (prevUIItemIndex <= accountDeviceGroups.length) {
      let middlePartIndexBase = Math.floor((currentAccountDeviceGroups.length / accountDeviceGroups.length) / 2) * accountDeviceGroups.length;
      prevUIItemIndex = middlePartIndexBase + (prevUIItemIndex % accountDeviceGroups.length);
    }

    if (prevUIItemIndex > -1) {
      itemRefs.current[prevUIItemIndex]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      setCurrentAccountDeviceGroup(accountDeviceGroups[prevItemIndex])
      // setTimeout(() => {
      //   // itemRefs.current[middlePartIndexBase + (prevUIItemIndex % accountDeviceGroups.length)]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      //   itemRefs.current[prevUIItemIndex]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      // }, 200);

      scrollIntoDebounced(itemRefs.current[prevUIItemIndex]!);
      enableButtonsDebounced();
    }
  }

  const onItemClick = (record: any) => {
    let itemIndex = accountDeviceGroups.findIndex(x => x.id === currentAccountDeviceGroup.id);
    setCurrentAccountDeviceGroup(accountDeviceGroups[itemIndex]);
  }

  const onDotClick = (item: any, index: any) => {
    setDisabledButtons(true)
    let middlePartIndexBase = Math.floor((currentAccountDeviceGroups.length / accountDeviceGroups.length) / 2) * accountDeviceGroups.length;
    itemRefs.current[middlePartIndexBase + index]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    setCurrentAccountDeviceGroup(accountDeviceGroups[index]);

    // setTimeout(() => {
    //   itemRefs.current[middlePartIndexBase + index]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    // }, 200);

    scrollIntoDebounced(itemRefs.current[middlePartIndexBase + index]!);
    enableButtonsDebounced();
  }

  const onNextClick = () => {
    setDisabledButtons(true)
    let nextItemIndex = accountDeviceGroups.findIndex(x => x.id === currentAccountDeviceGroup.id) + 1;
    if (nextItemIndex >= accountDeviceGroups.length) {
      nextItemIndex = 0;
    }
    const currentItemIndex = getCenteredItemIndex();
    let nextUIItemIndex = currentItemIndex + 1;

    // to scroll to middle one if it's the last section of the array
    if (nextUIItemIndex >= currentAccountDeviceGroups.length - accountDeviceGroups.length) {
      let middlePartIndexBase = Math.floor((currentAccountDeviceGroups.length / accountDeviceGroups.length) / 2) * accountDeviceGroups.length;
      nextUIItemIndex = middlePartIndexBase + (nextUIItemIndex % accountDeviceGroups.length);
    }

    if (nextUIItemIndex < currentAccountDeviceGroups.length) {
      itemRefs.current[nextUIItemIndex]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

      setCurrentAccountDeviceGroup(accountDeviceGroups[nextItemIndex]);

      // setTimeout(() => {
      //   itemRefs.current[nextUIItemIndex]!.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      // }, 200);

      scrollIntoDebounced(itemRefs.current[nextUIItemIndex]!);
      enableButtonsDebounced();
    }
  }

  const ListItem: React.FC<{ data: any }> = ({ data }) => (
    <Ripple>
      <group
        data-align="center"
        data-space="5"
        data-gap="5"
        data-wrap="no"
        //data-radius="5"
        data-contain=""
        data-cursor="pointer"
        data-disabled={data.isActive ? undefined : "grayscale"}>
        <group data-ratio="1:1" data-length="50" data-shrink="no" data-index="1" data-radius="5" data-color="main" >
          <icon data-icon-size="48" data-position="center">{data.icon}</icon>
        </group>
        <group data-direction="column" data-contain="" data-space="5" data-index="1">
          <Text data-weight="700" data-ellipsis="">
            {data.name}
          </Text>
          {!!data.description && <Marquee>
            <Text data-ellipsis="" data-light="">
              {data.description}
            </Text>
          </Marquee>}
        </group>
        {data.id === 'applications' && !!currentAccountDeviceGroup.numSetFiltersWithSchedule &&
          <icon data-opacity="30">schedule</icon>
        }
        {data.id === 'internet-pause' && currentAccountDeviceGroup.isBlocked &&
          <text data-opacity="30">{timer}</text>
        }
        {/* <separator data-vertical=""></separator> */}
        <group
          data-align="center"
          data-wrap="no"
          data-position="right"
          data-width="auto"
          data-index="1">
          <group data-space="10">
            <icon data-opacity="30">chevron_right</icon>
          </group>
        </group>
      </group>
    </Ripple>
  );

  return (
    <Popup
      adaptive
      title={modalTitle || `${(!currentAccountDeviceGroup.id ? "New Profile" : `Profile Name: ${currentAccountDeviceGroup.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="medium"
    >
      {loading &&
        <Loader />
      }
      <group >
        <group
          data-align="center"
          data-direction="column"
          data-wrap='no'
          data-background="main"
          data-position="absolute"
          data-top="0"
          data-height='fit'
        >
          <SvgDoodle />
        </group>
        <group>

          <Scroll draggable={false} showButtons={false} >
            <group data-name="profiles_slide" data-wrap="no" data-scroll-type="snap" data-type="snap" data-space-vertical="20" data-gap="10">
              {
                currentAccountDeviceGroups.map((item: any, index: any) => (
                  <group
                    key={`${item.id}-${index}`}
                    ref={(el: any) => (itemRefs.current[index] = el)}
                    data-position="center"
                    data-direction="column"
                    data-align="center"
                    data-gap="20"
                    data-length="120"
                    data-cursor="pointer"
                    className={item.id === currentAccountDeviceGroup.id ? "item selected" : "item"}
                    onClick={() => onItemClick(item)}>
                    <group data-wrap="no" >
                      <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="120" data-radius="full" data-background="main" data-color="white" data-border='2'>
                        <picture>
                          {item.icon ? <img src={getBase64ImageUrl(item.icon)} alt="" /> : <text data-text-size="xx-large" data-weight="700">{getStringFirstLetters(item.name!)}</text>}
                        </picture>
                      </group>
                      {
                        !!item.isBlocked &&
                        <group data-ratio="1:1" data-shrink="no" data-contain="" data-length="30" data-height="30" data-radius="full" data-background="main-dark" data-color="white" data-border='2' data-align-self="end" data-margin-left="-30">
                          <picture>
                            <icon data-position="center">pause</icon>
                          </picture>
                        </group>
                      }
                    </group>
                    <text data-color="white" data-weight="600" data-ellipsis="">{item.name}</text>
                  </group>
                ))
              }

            </group>
          </Scroll>
          <group data-justify="center" data-gap="5" data-align="center">
            {!!accountDeviceGroups.length &&
              <group disabled={disabledButtons || undefined} data-interactive="" data-width="auto" data-cursor="pointer" onClick={onPrevClick}>
                <group data-cursor="pointer" data-space-horizontal="30">
                  <icon data-length="50" data-color="white" data-opacity="80">keyboard_double_arrow_left</icon>
                </group>
              </group>
            }
            {accountDeviceGroups.map((item: any, index: number) => (
              <group disabled={disabledButtons || undefined} data-interactive="" data-width="auto" onClick={() => onDotClick(item, index)}>
                <group data-length="10" data-height="10" data-radius="30" data-cursor="pointer" data-border="" data-type="interact" data-background={item.id === currentAccountDeviceGroup.id ? "white" : ""}></group>
              </group>
            ))}
            {!!accountDeviceGroups.length &&
              <group disabled={disabledButtons || undefined} data-interactive="" data-width="auto" data-cursor="pointer" onClick={onNextClick}>
                <group data-cursor="pointer" data-space-horizontal="30">
                  <icon data-length="50" data-color="white" data-opacity="80" data-position="right">keyboard_double_arrow_right</icon>
                </group>
              </group>
            }
          </group>
        </group>
        <space data-height="80"></space>

      </group>




      <group data-margin="-60">
        <group data-space="15">
          <group data-contain="" data-radius="10" data-elevation="2" data-index="2">
            <ListView
              data-template="400"
              view={ListViewType.List}
              dataSource={visibleListItems}
              onItemClick={(item: any) => item.isActive ? onSelectedTab(item.id) : null}
              keyField="id"
              //data-space="5"
              listProps={{ "data-gap": "1" }}
              itemComponent={ListItem}
            />
          </group>
        </group>
      </group>

      <group data-space="15">
        <group data-contain="" data-radius="10" data-elevation="2" data-space="15" data-background="white" data-index="1">
          <group data-align="center" data-gap="10" >
            <Tooltip title="Edit"><Button primary data-space-horizontal="30" large text="Edit" onClick={onEdit}></Button></Tooltip>
            <Tooltip title="Delete"><Button large text="Delete Profile" onClick={onDeleteClick}></Button></Tooltip>
          </group>
        </group>
      </group>




      {!!showAccountDeviceGroupModal && (
        <AccountDeviceGroupModal
          modalTitle={(modalTitle || `${(!currentAccountDeviceGroup.id ? "New Profile" : `Profile Name: ${currentAccountDeviceGroup.name}`)}`) + " - " + listItems.find(x => x.id === preSelectedTab).name}
          open={showAccountDeviceGroupModal}
          showTabs={false}
          showGroupEditSection={false}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup}
          accountKiboshDevice={accountKiboshDevice}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountDeviceGroupModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {!!showAccountDeviceGroupCreationModal && (
        <AccountDeviceGroupCreationModal
          accountDeviceGroup={currentAccountDeviceGroup}
          open={showAccountDeviceGroupCreationModal}
          closeOnSave={true}
          kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference!}
          onClose={() => setShowAccountDeviceGroupCreationModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
      {!!showAccountKiboshVpnDeviceMenuModal && !!currentAccountKiboshVpnDevice && (
        <AccountKiboshDeviceMenuModal
          open={showAccountKiboshVpnDeviceMenuModal}
          closeOnSave={true}
          account={account!}
          accountKiboshDevice={currentAccountKiboshVpnDevice}
          deviceReference={currentAccountKiboshVpnDevice.associatedRouterClientId}
          onClose={() => setShowAccountKiboshVpnDeviceMenuModal(false)}
        />
      )}
    </Popup>
  );
}
