// 3rd-party modules
import history from './helpers/routeHelper';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ErrorBoundary } from 'react-error-boundary';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter, useLocation } from 'react-router-dom';

// project modules
import App from './App';
import ErrorPage from './pages/Error'
import * as Plugins from './plugins';
import './plugins/ignoreElements';
import { store } from './stores';

import './assets/styles/index.less';
import { isDesktop, isIOS, isMobile } from 'react-device-detect';
import { FC, useEffect, useState } from 'react';

isMobile && document.documentElement.classList.add("mobile");
isDesktop && document.documentElement.classList.add("desktop");
isIOS && document.documentElement.classList.add("ios");

window.onerror = (msg, url, line, col, error) => {
  // note that col & error are new to the HTML 5 and may not be supported in every browser
  console.error(msg, url, line, col, error);

  // TODO: Report the error via ajax
};

window.onunhandledrejection = (e: PromiseRejectionEvent) => {
  // it's not supported in all browsers solution would be to call this.setState(() => { throw err; }); in catch of your promise
  console.error(e);
};

const persistor = persistStore(store);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

Plugins.init();


// wrapper component to add in service worker details.
interface WrappedCompProps {
  appUpdatePending: boolean;
  updateAction: () => void;
};
const withSwRegistration = (WrappedComp: FC<WrappedCompProps>, ) => {
  return () => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = useState(false);
    const location = useLocation();
    // updates the state when a new update is pending.
    const onSWUpdate = () => {
      setAppUpdatePending(!appUpdatePending);
    }
    // action for updating the service worker.
    const updateAction = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({type: 'SKIP_WAITING'});
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener('statechange', (event: Event) => {
              const { state = '' } =  event.target as unknown as {state: string} || {};
              if (state === 'activated') {
                window.location.reload();
              }
            });
          }
        });
      }
    };
    // effect added from router location to check for a new service worker on every
    // page transition (change of route).
    useEffect(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }
    }, [location]);

    // registers the service worker based on config setting.
    serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    return (
      <WrappedComp updateAction={updateAction} appUpdatePending={appUpdatePending} />
    )
  }
};

const AppWithSwRegistration = withSwRegistration(App);

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/*// @ts-ignore */}
        <HistoryRouter history={history}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <AppWithSwRegistration />
          </ErrorBoundary>
        </HistoryRouter>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// // serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register();
