// defines
const {
  REACT_APP_REQUEST_TIMEOUT,
  REACT_APP_DEFAULT_TABLE_PAGE_SIZE,
  REACT_APP_NO_CONSOLE,
  REACT_APP_API_URL,
  REACT_APP_LOGIN_PAGE_URL,
  REACT_APP_NEW_SUBSCRIPTION_PAGE_URL,
  REACT_APP_STRIPE_RETURN_URL,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_STRIPE_PUBLISHABLE_KEY
} = process.env;

if (!REACT_APP_REQUEST_TIMEOUT) throw Error('REACT_APP_REQUEST_TIMEOUT env. var. is required.');
if (!REACT_APP_API_URL) throw Error('REACT_APP_API_URL env. var. is required.');
if (!REACT_APP_LOGIN_PAGE_URL) throw Error('REACT_APP_LOGIN_PAGE_URL env. var. is required.');
if (!REACT_APP_NEW_SUBSCRIPTION_PAGE_URL) throw Error('REACT_APP_NEW_SUBSCRIPTION_PAGE_URL env. var. is required.');
if (!REACT_APP_STRIPE_RETURN_URL) throw Error('REACT_APP_STRIPE_RETURN_URL env. var. is required.');
if (!REACT_APP_RECAPTCHA_SITE_KEY) throw Error('REACT_APP_RECAPTCHA_SITE_KEY env. var. is required.');
if (!REACT_APP_STRIPE_PUBLISHABLE_KEY) throw Error('REACT_APP_STRIPE_PUBLISHABLE_KEY env. var. is required.');

export interface IConfig {
  apiUrl: string;
  defaultTablePageSize: number;
  noConsole: boolean;
  requestTimeout: number;
  loginPageUrl: string;
  newSubPageUrl: string;
  stripeReturnUrl: string;
  recaptchaSiteKey: string;
  stripePublishableKey: string;
  gaMeasurementId?: string;
  serverSettings?: any;
}

const getServerSettings = async () => {
  const baseUrl = process.env.NODE_ENV === "development"
    ? '/api'
    : REACT_APP_API_URL;
  const response = await fetch(`${baseUrl}/frontendSettings`);

  if (response.ok) {
    const result = await response.json();

    return result.data?.value;
  }

  return null;
};

const getSettings = async () => {
  let serverSettings = await getServerSettings();

  const config: IConfig = {
    apiUrl: REACT_APP_API_URL,
    defaultTablePageSize: parseInt(REACT_APP_DEFAULT_TABLE_PAGE_SIZE || '50'),
    noConsole: (REACT_APP_NO_CONSOLE || false) === 'true',
    requestTimeout: parseInt(serverSettings?.requestTimeout || REACT_APP_REQUEST_TIMEOUT),
    loginPageUrl: REACT_APP_LOGIN_PAGE_URL,
    newSubPageUrl: REACT_APP_NEW_SUBSCRIPTION_PAGE_URL,
    stripeReturnUrl: REACT_APP_STRIPE_RETURN_URL,
    recaptchaSiteKey: REACT_APP_RECAPTCHA_SITE_KEY,
    stripePublishableKey: REACT_APP_STRIPE_PUBLISHABLE_KEY,
    serverSettings
  };

  return config;
};

/*
const Config: IConfig = {
  apiUrl: REACT_APP_API_URL,
  defaultTablePageSize: parseInt(REACT_APP_DEFAULT_TABLE_PAGE_SIZE || '50'),
  requestTimeout: parseInt(REACT_APP_REQUEST_TIMEOUT),
  loginPageUrl: REACT_APP_LOGIN_PAGE_URL,
  newSubPageUrl: REACT_APP_NEW_SUBSCRIPTION_PAGE_URL,
  stripeReturnUrl: REACT_APP_STRIPE_RETURN_URL,
  recaptchaSiteKey: REACT_APP_RECAPTCHA_SITE_KEY,
  stripePublishableKey: REACT_APP_STRIPE_PUBLISHABLE_KEY
}

export default Config;
*/

export default await getSettings();
